export default class extends Marko.Component {
  onMount() {
    const el = this.getEl("down-icon");

    if (!el) {
      return;
    }

    el.addEventListener("animationend", () => {
      el.classList.remove("animate__bounce");
      setTimeout(() => el.classList.add("animate__bounce"), 1000);
    });
  }
}
