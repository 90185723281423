import { directus } from "../lib/directus";
import { v4 as uuidv4 } from "uuid";

const clientIdKey = "clientId";

export default class {
  onMount() {
    if (typeof localStorage === "object") {
      const clientId =
        localStorage.getItem(clientIdKey) ?? `${Date.now()}_${uuidv4()}`;
      localStorage.setItem(clientIdKey, clientId);

      const path = location?.pathname ?? null;
      const page = path?.length ? path : "index";

      directus
        .items("Event")
        .createOne({
          type: "view",
          value: page,
          client_id: clientId,
        })
        .catch(() => {
          console.log("Error");
        });
    }
  }
}
