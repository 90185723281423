import { directus } from "../../lib/directus.ts";
style {
  .menu-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    transition: margin-top 0.1s;
  }

  .desktop-menu {
    width: 100%;
    display: flex;
    align-items: center;
    place-content: center;
    gap: 70px;
    padding: 50px;
    z-index: 1;
    position: relative;
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px);
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.25);
  }

  .menu-small {
    padding: 10px;
  }

  .mobile-menu {
    width: 100%;
    align-items: center;
    place-content: space-between;
    gap: 70px;
    display: none;
  }

  .mobile-menu-items {
    display: flex;
    gap: 20px;
    place-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    backdrop-filter: blur(25px);
    background-color: rgba(255, 255, 255, 0.8);
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.5s;
    height: 100vh;
    width: 100%;
  }

  .menu-open {
    pointer-events: all;
    opacity: 1;
  }

  .menu-item-wrapper {
    font-family: var(--header-font);
    text-align: center;
    display: flex;
    place-content: center;
    align-items: center;
  }
  .menu-item {
    display: flex;
    place-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: nowrap;
    font-size: 25px;
    font-weight: 400;
    position: relative;
  }
  .menu-center {
    display: flex;
    place-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    max-height: 200px;
  }
  .menu-dash {
    height: 2px;
    width: 8px;
    background-color: var(--text-color);
    display: block;
    opacity: 0;
    transition: 0.5s opacity, 0.5s left, 0.5s right;
    position: absolute;
  }

  .menu-dash:first-of-type {
    left: -20px;
  }

  .menu-dash:last-of-type {
    right: -20px;
  }

  .menu-item:hover > .menu-dash,
  .menu-active .menu-dash {
    opacity: 1;
    margin: 0px;
  }

  .menu-item:hover > .menu-dash:first-of-type,
  .menu-active .menu-dash:first-of-type {
    left: -15px;
  }

  .menu-item:hover > .menu-dash:last-of-type,
  .menu-active .menu-dash:last-of-type {
    right: -15px;
  }

  .menu-hideable {
    opacity: 0;
    transition: opacity 0.25s, transform 0.5s, max-height 0.25s;
    transform: scale(0);
    max-height: 0px;
  }

  .menu-part-visible {
    opacity: 1;
    transform: scale(1);
    max-height: 300px;
  }

  .mobile-toggle {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    padding: 20px;
    display: none;
    place-content: center;
    align-items: center;
    font-size: 35px;
  }

  @media only screen and (max-width: 768px) {
    /* .menu-center {
      order: -1;
    } */

    .desktop-menu .menu-item {
      display: none;
    }

    .mobile-menu {
      display: flex;
      position: relative;
      z-index: 1;
    }

    .mobile-toggle {
      display: flex;
    }
  }
}
class {
  onCreate(input) {
    this.state = {
      open: false,
      small: input?.expandable ? false : true,
    };
  }

  onMount() {
    if (this.input?.expandable) {
      window.onscroll = this.checkIfSmall.bind(this);
      this.checkIfSmall();
    }
  }

  checkIfSmall() {
    if (
      document.body.scrollTop > 25 ||
      document.documentElement.scrollTop > 25
    ) {
      this.state.small = true;
    } else {
      this.state.small = false;
      this.state.open = false;
    }
  }

  toggle() {
    this.state.open = !this.state.open;
  }

  close() {
    this.state.open = false;
  }
}
$ {
  const {
    url: { pathname },
  } = $global;
}
$ {
  const defaultLinks = [
    {
      name: "Registry",
      slug: "registry",
    },
  ];
}
$ const links = [...$global.links, ...defaultLinks];
macro|{ slug, name }| name="menu-item"
  div [
    class=["menu-item-wrapper", { "menu-active": pathname?.includes(slug) }]
    on-click("close")
  ]
    div.menu-item
      span.menu-dash
      a href=`/${slug}` -- ${name}
      span.menu-dash
div.menu-wrapper style={
  marginTop: input?.expandable && !state.small ? "100px" : "0px",
}
  div class=["desktop-menu", { "menu-small": state.small }]
    for|item, index| of=links.filter(({ slug }) => slug !== "index")
      if(index === Math.floor(links.length / 2) && !!input.center?.renderBody)
        div.menu-center
          div class=["menu-hideable", { "menu-part-visible": state.small }]
            a href="/"
              ${input.logo}
          div class=["menu-hideable", { "menu-part-visible": !state.small }]
            a href="/"
              ${input.center}
      menu-item slug=item.slug name=item.name
    if(state.small)
      div.mobile-toggle on-click("toggle")
        feather-icon icon=(state.open ? "x" : "menu")
  div class=["mobile-menu-items", { "menu-open": state.open }]
    for|item, index| of=links.filter(({ slug }) => slug !== "index")
      menu-item slug=item.slug name=item.name
